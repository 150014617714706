import React from 'react';
import Layout from '../../components/Layout';
import { isLoggedIn } from '../../utils';
import { navigate } from '@reach/router';

export default () => {
  if (!isLoggedIn()) {
    navigate('/account');
    return null;
  } else {
    return (
      <Layout>
        <div>1111111111111111111</div>
      </Layout>
    )
  }

}
